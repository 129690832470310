import React, { useState, useRef, useEffect } from "react";
import "bulma/css/bulma.css";
import { Link } from "gatsby";

export default function Navbar() {
  //Navbar Links
  const otherNavlinks = [
    { tag: "Blog", to: "/blog" },
    { tag: "Contact Us", to: "/contact-us" },
  ];

  const dropdownLinks = {
    Services: [
      {
        tag: "Salesforce",
        to: "/salesforce",
      },
      {
        tag: "Data Engineering",
        to: "/data-engineering",
      },
      { tag: "SAP", to: "/sap" },
      { tag: "Talent Acquisition", to: "/talent-acquisition" },
    ],
    Products: [
      {
        tag: "Timesheet App",
        to: "/products/timesheet",
      },
      {
        tag: "ATS App",
        to: "/products/ats",
      },
    ],
    "Case Study": [
      {
        tag: "Salesforce Integration",
        to: "/case-study/salesforce-integration",
      },
      {
        tag: "CI/CD Integration",
        to: "/case-study/CICD-integration",
      },
    ],
    Careers: [
      {
        tag: "Internship",
        to: "https://docs.google.com/forms/d/e/1FAIpQLSfD0xkn-d5QnLKrjnBuiKejMt6Ap10p9oY4j4BzKnSdj3gssw/viewform?embedded=true",
      },
      {
        tag: "Bootcamp",
        to: "https://docs.google.com/forms/d/e/1FAIpQLScy5UhNPCinuFn3RPzA0uNKqedfGuqexHrYs4V3XTQe6mMCqw/viewform?embedded=true",
      },
      {
        tag: "Apply Job",
        to: "https://docs.google.com/forms/d/e/1FAIpQLSfYcawOTjM4G6BKEQvXlNi9yFy5lozz8Onk_BOQMD7OsR6eoA/viewform?embedded=true",
      },
    ],
  };

  // Styling for logo
  const logoStyle = {
    marginTop: "10px",
    maxHeight: "3rem",
  };

  // Variables for toggling Navbar
  const hbtoggle1 = useRef();
  const hbtoggle2 = useRef();

  // To toggle logo while scrolling
  const [isScrolled, setIsScrolled] = useState(false);

  //Toggle background color of navbar when hamburger is clicked
  const [isTransparent, setIsTransparent] = useState(true);

  // Avoid checking for transparent navbar while scroling
  const [avoidScrollCheck, setAvoidScrollCheck] = useState(false);

  //Transparent Navbar included paths
  const includedPaths = [
    "/",
    "/salesforce",
    "/data-engineering",
    "/sap",
    "/talent-acquisition",
    "/products/timesheet",
    "/products/ats",
    "/case-study/salesforce-integration",
    "/case-study/CICD-integration",
    "/blog",
  ];

  // Hamburger button toggle function
  const hamBurgerToggle = () => {
    hbtoggle1.current.classList.toggle("is-active");
    hbtoggle2.current.classList.toggle("is-active");
    hbtoggle1.current.classList.contains("is-active")
      ? setIsTransparent(false)
      : isScrolled
      ? setIsTransparent(false)
      : setIsTransparent(true);
  };

  //Remove active className from Navbar
  const removeActive = () => {
    hbtoggle1.current.classList.remove("is-active");
    hbtoggle2.current.classList.remove("is-active");
  };

  useEffect(() => {
    const path = window.location.pathname;
    if (!includedPaths.includes(path)) {
      setIsTransparent(false);
      setAvoidScrollCheck(true);
    }
  }, []);

  // Close the menu when the user clicks outside the hamburger menu
  if (typeof window !== "undefined") {
    if (document.getElementById("mainView")) {
      document.getElementById("mainView").addEventListener("click", () => {
        removeActive();
      });
    }
  }

  // Add shadow and change background for Navbar
  if (typeof window !== "undefined") {
    window.addEventListener("scroll", () => {
      const visionSectionTop = document.getElementById("section-ends");
      let offset = 0;
      if (visionSectionTop) {
        offset = visionSectionTop.offsetTop - 100;
      }
      if (window.scrollY > offset) {
        setIsTransparent(false);
        setIsScrolled(true);
      } else {
        if (avoidScrollCheck) {
          setIsTransparent(false);
        } else {
          setIsTransparent(true);
        }
        setIsScrolled(false);
      }
    });
  }

  return (
    <nav
      className="navbar is-fixed-top is-transparent"
      role="navigation"
      aria-label="main navigation"
      id="navBar"
      style={{
        backgroundColor: isTransparent ? "transparent" : "rgb(53 91 130)",
        transitionDuration: "0s",
        transitionProperty: "background-color",
      }}
    >
      {/* Brand Logo */}
      <div className="navbar-brand">
        <Link className="navbar-item" to="/">
          <img
            src={isTransparent ? "/img/dbt-logo-white.svg" : "/img/dbt-logo.svg"}
            alt="Logo"
            style={logoStyle}
          />
        </Link>
        {/* Hamburger Button */}
        <div
          role="button"
          className="navbar-burger"
          aria-label="menu"
          aria-expanded="false"
          aria-hidden="true"
          ref={hbtoggle1}
          onClick={hamBurgerToggle}
          style={{
            ...logoStyle,
            transitionDuration: "0s",
            transitionProperty: "color",
            color: isTransparent ? "#fff" : "var(--primary)",
          }}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </div>
      </div>
      {/* Links of Navbar */}
      <div className="navbar-menu" ref={hbtoggle2}>
        <div className="navbar-end mr-6 ">
          {Object.keys(dropdownLinks).map((links, index) => (
            <div className="navbar-item" key={index}>
              <div className="navbar-item has-dropdown is-hoverable">
                <div
                  className={"navbar-link navbar-item links has-text-weight-semibold "}
                  style={{
                    color: isTransparent ? "#fff" : "var(--primary)",
                  }}
                >
                  {links}
                </div>
                <div className="navbar-dropdown is-right">
                  {dropdownLinks[links].map((link) => (
                    <React.Fragment key={link.tag}>
                      {links === "Careers" ? (
                        <a
                          href={link.to}
                          target="_blank"
                          rel="noreferrer"
                          className={"navbar-item links has-text-weight-semibold "}
                          onClick={removeActive}
                          state={{ tag: "" }}
                        >
                          {" "}
                          {link.tag}
                        </a>
                      ) : (
                        <Link
                          to={link.to}
                          className={"navbar-item links has-text-weight-semibold "}
                          onClick={removeActive}
                          state={{ tag: "" }}
                          activeStyle={{ color: "var(--primary)" }}
                        >
                          {link.tag}
                        </Link>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div>
          ))}
          {/* Other Links Loop */}
          {otherNavlinks.map((link) => (
            <Link
              to={link.to}
              key={link.tag}
              className={
                "navbar-item links has-text-weight-semibold " +
                (isTransparent ? "text-white-link" : "")
              }
              onClick={removeActive}
              state={{ tag: "" }}
              style={{
                paddingRight: "40px",
                color: isTransparent ? "#fff" : "var(--primary)",
              }}
              activeStyle={{ color: "var(--primary)" }}
            >
              {link.tag}
            </Link>
          ))}
        </div>
      </div>
    </nav>
  );
}
