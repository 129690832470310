import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

import "bulma/css/bulma.css";
require("../assets/css/global.css");

export default function Layout({ children }) {
  return (
    <div className="layout " style={{ maxWidth: "1920px", margin: "0 auto" }}>
      {/* Navbar Component */}
        <Navbar />
      {/* Children i.e. everyting displayed on pages. */}
      <div className={"content "} style={{ minHeight: "100vh" }} id="mainView">
        {children}
      </div>
      {/* Footer  */}
      <hr />
      <Footer />
    </div>
  );
}
