import React from "react";
import { FaYoutube, FaLinkedinIn, FaArrowCircleUp } from "react-icons/fa";
import { footerIcons } from "../styles/common.module.css";

export default function Footer() {
  // Footer Icons
  const ytIcon = {
    color: "#ff0000",
    textDecoration: "none",
  };
  const lnIcon = {
    color: "#2867B2",
    textDecoration: "none",
  };

  // Scroll to top
  const scrollToTop = () => {
    window.scroll({ top: 0 });
  };
  const currentYear = new Date().getFullYear();
  return (
    <footer>
      <div className="content has-text-centered">
        <p className="has-text-weight-bold">
          &copy; {currentYear} Digital Biz Tech
        </p>
        <div className="is-flex is-justify-content-center">
          <div className={footerIcons}>
            <a
              href="https://www.youtube.com/channel/UCO7cwgFkV4N5mN77d1TNeVQ"
              target="_blank"
              rel="noreferrer"
              style={ytIcon}
            >
              <FaYoutube />
            </a>
          </div>
          <div className={footerIcons}>
            <a
              href="https://in.linkedin.com/company/digital-biz-tech"
              target="_blank"
              rel="noreferrer"
              style={lnIcon}
            >
              <FaLinkedinIn />
            </a>
          </div>
        </div>
        <div onClick={scrollToTop} aria-hidden="true">
          <div className={"is-pulled-right  " + footerIcons}>
            <FaArrowCircleUp />
          </div>
        </div>
      </div>
    </footer>
  );
}
