// extracted by mini-css-extract-plugin
export var atsImg = "common-module--atsImg--Rlceo";
export var blog1Img = "common-module--blog1Img--sSHOh";
export var blog2Img = "common-module--blog2Img--w29OM";
export var cardHover = "common-module--cardHover--VArDW";
export var cardHoverText = "common-module--cardHoverText--8gTlS";
export var cardHoverTitle = "common-module--cardHoverTitle--qoidA";
export var cardTitle = "common-module--cardTitle--ib8M8";
export var cicdCaseImg = "common-module--cicdCaseImg--T5hDe";
export var dbt = "common-module--dbt--ZP8Rj";
export var dbtInfo = "common-module--dbtInfo--8Fm1g";
export var footerIcons = "common-module--footerIcons--e7ESh";
export var pcimages = "common-module--pcimages--9+7w-";
export var salesforceCaseImg = "common-module--salesforceCaseImg--C1X5Y";
export var sectionHeading = "common-module--sectionHeading--Wiw8z";
export var timesheetImg = "common-module--timesheetImg--II4jd";
export var valuesColumns = "common-module--valuesColumns--e6O6R";
export var valuesColumnsRev = "common-module--valuesColumnsRev--s9umJ";
export var valuesText = "common-module--valuesText--sBNXN";
export var visionContainer = "common-module--visionContainer--ShwrM";
export var visionHeading = "common-module--visionHeading--C0ZG3";
export var visions = "common-module--visions--12F66";
export var workCard = "common-module--workCard---vUEE";
export var workCardTitle = "common-module--workCardTitle--8JylQ";